/**
 * ccfatigue
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import PageTestModel from "../model/PageTestModel";

/**
 * Tests service.
 * @module api/TestsApi
 * @version 0.1.0
 */
export default class TestsApi {
  /**
   * Constructs a new TestsApi.
   * @alias module:api/TestsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Get Tests
   * Get all tests for specific experiment
   * @param {Number} experimentId
   * @param {Object} opts Optional parameters
   * @param {Number} opts.page  (default to 1)
   * @param {Number} opts.size  (default to 50)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PageTestModel} and HTTP response
   */
  getTestsWithHttpInfo(experimentId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'experimentId' is set
    if (experimentId === undefined || experimentId === null) {
      throw new Error(
        "Missing the required parameter 'experimentId' when calling getTests"
      );
    }

    let pathParams = {};
    let queryParams = {
      experiment_id: experimentId,
      page: opts["page"],
      size: opts["size"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = PageTestModel;
    return this.apiClient.callApi(
      "/tests",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Get Tests
   * Get all tests for specific experiment
   * @param {Number} experimentId
   * @param {Object} opts Optional parameters
   * @param {Number} opts.page  (default to 1)
   * @param {Number} opts.size  (default to 50)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PageTestModel}
   */
  getTests(experimentId, opts) {
    return this.getTestsWithHttpInfo(experimentId, opts).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }
}
