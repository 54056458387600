/**
 * ccfatigue
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import HysteresisLoop from "./HysteresisLoop";

/**
 * The FatigueTest model module.
 * @module model/FatigueTest
 * @version 0.1.0
 */
class FatigueTest {
  /**
   * Constructs a new <code>FatigueTest</code>.
   * @alias module:model/FatigueTest
   * @param specimenId {Number}
   * @param specimenName {String}
   * @param totalDissipatedEnergy {Number}
   * @param runOut {Boolean}
   * @param stressRatio {Number}
   * @param hysteresisLoops {Array.<module:model/HysteresisLoop>}
   * @param nCycles {Array.<Number>}
   * @param creep {Array.<Number>}
   * @param hysteresisArea {Array.<Number>}
   * @param stiffness {Array.<Number>}
   * @param stressAtFailure {Number}
   * @param strainAtFailure {Number}
   * @param nFail {Number}
   */
  constructor(
    specimenId,
    specimenName,
    totalDissipatedEnergy,
    runOut,
    stressRatio,
    hysteresisLoops,
    nCycles,
    creep,
    hysteresisArea,
    stiffness,
    stressAtFailure,
    strainAtFailure,
    nFail
  ) {
    FatigueTest.initialize(
      this,
      specimenId,
      specimenName,
      totalDissipatedEnergy,
      runOut,
      stressRatio,
      hysteresisLoops,
      nCycles,
      creep,
      hysteresisArea,
      stiffness,
      stressAtFailure,
      strainAtFailure,
      nFail
    );
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(
    obj,
    specimenId,
    specimenName,
    totalDissipatedEnergy,
    runOut,
    stressRatio,
    hysteresisLoops,
    nCycles,
    creep,
    hysteresisArea,
    stiffness,
    stressAtFailure,
    strainAtFailure,
    nFail
  ) {
    obj["specimen_id"] = specimenId;
    obj["specimen_name"] = specimenName;
    obj["total_dissipated_energy"] = totalDissipatedEnergy;
    obj["run_out"] = runOut;
    obj["stress_ratio"] = stressRatio;
    obj["hysteresis_loops"] = hysteresisLoops;
    obj["n_cycles"] = nCycles;
    obj["creep"] = creep;
    obj["hysteresis_area"] = hysteresisArea;
    obj["stiffness"] = stiffness;
    obj["stress_at_failure"] = stressAtFailure;
    obj["strain_at_failure"] = strainAtFailure;
    obj["n_fail"] = nFail;
  }

  /**
   * Constructs a <code>FatigueTest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FatigueTest} obj Optional instance to populate.
   * @return {module:model/FatigueTest} The populated <code>FatigueTest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FatigueTest();

      if (data.hasOwnProperty("specimen_id")) {
        obj["specimen_id"] = ApiClient.convertToType(
          data["specimen_id"],
          "Number"
        );
      }
      if (data.hasOwnProperty("specimen_name")) {
        obj["specimen_name"] = ApiClient.convertToType(
          data["specimen_name"],
          "String"
        );
      }
      if (data.hasOwnProperty("total_dissipated_energy")) {
        obj["total_dissipated_energy"] = ApiClient.convertToType(
          data["total_dissipated_energy"],
          "Number"
        );
      }
      if (data.hasOwnProperty("run_out")) {
        obj["run_out"] = ApiClient.convertToType(data["run_out"], "Boolean");
      }
      if (data.hasOwnProperty("stress_ratio")) {
        obj["stress_ratio"] = ApiClient.convertToType(
          data["stress_ratio"],
          "Number"
        );
      }
      if (data.hasOwnProperty("hysteresis_loops")) {
        obj["hysteresis_loops"] = ApiClient.convertToType(
          data["hysteresis_loops"],
          [HysteresisLoop]
        );
      }
      if (data.hasOwnProperty("n_cycles")) {
        obj["n_cycles"] = ApiClient.convertToType(data["n_cycles"], ["Number"]);
      }
      if (data.hasOwnProperty("creep")) {
        obj["creep"] = ApiClient.convertToType(data["creep"], ["Number"]);
      }
      if (data.hasOwnProperty("hysteresis_area")) {
        obj["hysteresis_area"] = ApiClient.convertToType(
          data["hysteresis_area"],
          ["Number"]
        );
      }
      if (data.hasOwnProperty("stiffness")) {
        obj["stiffness"] = ApiClient.convertToType(data["stiffness"], [
          "Number",
        ]);
      }
      if (data.hasOwnProperty("stress_at_failure")) {
        obj["stress_at_failure"] = ApiClient.convertToType(
          data["stress_at_failure"],
          "Number"
        );
      }
      if (data.hasOwnProperty("strain_at_failure")) {
        obj["strain_at_failure"] = ApiClient.convertToType(
          data["strain_at_failure"],
          "Number"
        );
      }
      if (data.hasOwnProperty("n_fail")) {
        obj["n_fail"] = ApiClient.convertToType(data["n_fail"], "Number");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} specimen_id
 */
FatigueTest.prototype["specimen_id"] = undefined;

/**
 * @member {String} specimen_name
 */
FatigueTest.prototype["specimen_name"] = undefined;

/**
 * @member {Number} total_dissipated_energy
 */
FatigueTest.prototype["total_dissipated_energy"] = undefined;

/**
 * @member {Boolean} run_out
 */
FatigueTest.prototype["run_out"] = undefined;

/**
 * @member {Number} stress_ratio
 */
FatigueTest.prototype["stress_ratio"] = undefined;

/**
 * @member {Array.<module:model/HysteresisLoop>} hysteresis_loops
 */
FatigueTest.prototype["hysteresis_loops"] = undefined;

/**
 * @member {Array.<Number>} n_cycles
 */
FatigueTest.prototype["n_cycles"] = undefined;

/**
 * @member {Array.<Number>} creep
 */
FatigueTest.prototype["creep"] = undefined;

/**
 * @member {Array.<Number>} hysteresis_area
 */
FatigueTest.prototype["hysteresis_area"] = undefined;

/**
 * @member {Array.<Number>} stiffness
 */
FatigueTest.prototype["stiffness"] = undefined;

/**
 * @member {Number} stress_at_failure
 */
FatigueTest.prototype["stress_at_failure"] = undefined;

/**
 * @member {Number} strain_at_failure
 */
FatigueTest.prototype["strain_at_failure"] = undefined;

/**
 * @member {Number} n_fail
 */
FatigueTest.prototype["n_fail"] = undefined;

export default FatigueTest;
