/**
 * ccfatigue
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The HysteresisLoop model module.
 * @module model/HysteresisLoop
 * @version 0.1.0
 */
class HysteresisLoop {
  /**
   * Constructs a new <code>HysteresisLoop</code>.
   * @alias module:model/HysteresisLoop
   * @param nCycles {Array.<Number>}
   * @param strain {Array.<Number>}
   * @param stress {Array.<Number>}
   */
  constructor(nCycles, strain, stress) {
    HysteresisLoop.initialize(this, nCycles, strain, stress);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, nCycles, strain, stress) {
    obj["n_cycles"] = nCycles;
    obj["strain"] = strain;
    obj["stress"] = stress;
  }

  /**
   * Constructs a <code>HysteresisLoop</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HysteresisLoop} obj Optional instance to populate.
   * @return {module:model/HysteresisLoop} The populated <code>HysteresisLoop</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HysteresisLoop();

      if (data.hasOwnProperty("n_cycles")) {
        obj["n_cycles"] = ApiClient.convertToType(data["n_cycles"], ["Number"]);
      }
      if (data.hasOwnProperty("strain")) {
        obj["strain"] = ApiClient.convertToType(data["strain"], ["Number"]);
      }
      if (data.hasOwnProperty("stress")) {
        obj["stress"] = ApiClient.convertToType(data["stress"], ["Number"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Array.<Number>} n_cycles
 */
HysteresisLoop.prototype["n_cycles"] = undefined;

/**
 * @member {Array.<Number>} strain
 */
HysteresisLoop.prototype["strain"] = undefined;

/**
 * @member {Array.<Number>} stress
 */
HysteresisLoop.prototype["stress"] = undefined;

export default HysteresisLoop;
