/**
 * ccfatigue
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AnalysisResult from "../model/AnalysisResult";

/**
 * Analysis service.
 * @module api/AnalysisApi
 * @version 0.1.0
 */
export default class AnalysisApi {
  /**
   * Constructs a new AnalysisApi.
   * @alias module:api/AnalysisApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Run Cld File
   * @param {module:model/CldMethod} method
   * @param {Number} ucs
   * @param {Number} uts
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  runCldFileWithHttpInfo(method, ucs, uts, file) {
    let postBody = null;
    // verify the required parameter 'method' is set
    if (method === undefined || method === null) {
      throw new Error(
        "Missing the required parameter 'method' when calling runCldFile"
      );
    }
    // verify the required parameter 'ucs' is set
    if (ucs === undefined || ucs === null) {
      throw new Error(
        "Missing the required parameter 'ucs' when calling runCldFile"
      );
    }
    // verify the required parameter 'uts' is set
    if (uts === undefined || uts === null) {
      throw new Error(
        "Missing the required parameter 'uts' when calling runCldFile"
      );
    }
    // verify the required parameter 'file' is set
    if (file === undefined || file === null) {
      throw new Error(
        "Missing the required parameter 'file' when calling runCldFile"
      );
    }

    let pathParams = {};
    let queryParams = {
      method: method,
      ucs: ucs,
      uts: uts,
    };
    let headerParams = {};
    let formParams = {
      file: file,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = File;
    return this.apiClient.callApi(
      "/analysis/cld/file",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Run Cld File
   * @param {module:model/CldMethod} method
   * @param {Number} ucs
   * @param {Number} uts
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  runCldFile(method, ucs, uts, file) {
    return this.runCldFileWithHttpInfo(method, ucs, uts, file).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Run Cycle Counting File
   * @param {module:model/CycleCountingMethod} method
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  runCycleCountingFileWithHttpInfo(method, file) {
    let postBody = null;
    // verify the required parameter 'method' is set
    if (method === undefined || method === null) {
      throw new Error(
        "Missing the required parameter 'method' when calling runCycleCountingFile"
      );
    }
    // verify the required parameter 'file' is set
    if (file === undefined || file === null) {
      throw new Error(
        "Missing the required parameter 'file' when calling runCycleCountingFile"
      );
    }

    let pathParams = {};
    let queryParams = {
      method: method,
    };
    let headerParams = {};
    let formParams = {
      file: file,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = File;
    return this.apiClient.callApi(
      "/analysis/cycleCounting/file",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Run Cycle Counting File
   * @param {module:model/CycleCountingMethod} method
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  runCycleCountingFile(method, file) {
    return this.runCycleCountingFileWithHttpInfo(method, file).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Run Damage Summation File
   * @param {module:model/DamageSummationMethod} method
   * @param {File} sncFile
   * @param {File} cycFile
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  runDamageSummationFileWithHttpInfo(method, sncFile, cycFile) {
    let postBody = null;
    // verify the required parameter 'method' is set
    if (method === undefined || method === null) {
      throw new Error(
        "Missing the required parameter 'method' when calling runDamageSummationFile"
      );
    }
    // verify the required parameter 'sncFile' is set
    if (sncFile === undefined || sncFile === null) {
      throw new Error(
        "Missing the required parameter 'sncFile' when calling runDamageSummationFile"
      );
    }
    // verify the required parameter 'cycFile' is set
    if (cycFile === undefined || cycFile === null) {
      throw new Error(
        "Missing the required parameter 'cycFile' when calling runDamageSummationFile"
      );
    }

    let pathParams = {};
    let queryParams = {
      method: method,
    };
    let headerParams = {};
    let formParams = {
      sncFile: sncFile,
      cycFile: cycFile,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = File;
    return this.apiClient.callApi(
      "/analysis/damageSummation/file",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Run Damage Summation File
   * @param {module:model/DamageSummationMethod} method
   * @param {File} sncFile
   * @param {File} cycFile
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  runDamageSummationFile(method, sncFile, cycFile) {
    return this.runDamageSummationFileWithHttpInfo(
      method,
      sncFile,
      cycFile
    ).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Run Fatigue Failure File
   * @param {module:model/FatigueFailureMethod} method
   * @param {module:model/FatigueModel} snModel
   * @param {Number} desirableAngle
   * @param {Number} offAxisAngle
   * @param {File} xFile
   * @param {File} yFile
   * @param {File} fFile
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AnalysisResult} and HTTP response
   */
  runFatigueFailureFileWithHttpInfo(
    method,
    snModel,
    desirableAngle,
    offAxisAngle,
    xFile,
    yFile,
    fFile
  ) {
    let postBody = null;
    // verify the required parameter 'method' is set
    if (method === undefined || method === null) {
      throw new Error(
        "Missing the required parameter 'method' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'snModel' is set
    if (snModel === undefined || snModel === null) {
      throw new Error(
        "Missing the required parameter 'snModel' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'desirableAngle' is set
    if (desirableAngle === undefined || desirableAngle === null) {
      throw new Error(
        "Missing the required parameter 'desirableAngle' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'offAxisAngle' is set
    if (offAxisAngle === undefined || offAxisAngle === null) {
      throw new Error(
        "Missing the required parameter 'offAxisAngle' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'xFile' is set
    if (xFile === undefined || xFile === null) {
      throw new Error(
        "Missing the required parameter 'xFile' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'yFile' is set
    if (yFile === undefined || yFile === null) {
      throw new Error(
        "Missing the required parameter 'yFile' when calling runFatigueFailureFile"
      );
    }
    // verify the required parameter 'fFile' is set
    if (fFile === undefined || fFile === null) {
      throw new Error(
        "Missing the required parameter 'fFile' when calling runFatigueFailureFile"
      );
    }

    let pathParams = {};
    let queryParams = {
      method: method,
      snModel: snModel,
      desirableAngle: desirableAngle,
      offAxisAngle: offAxisAngle,
    };
    let headerParams = {};
    let formParams = {
      xFile: xFile,
      yFile: yFile,
      fFile: fFile,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = AnalysisResult;
    return this.apiClient.callApi(
      "/analysis/fatigueFailure/file",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Run Fatigue Failure File
   * @param {module:model/FatigueFailureMethod} method
   * @param {module:model/FatigueModel} snModel
   * @param {Number} desirableAngle
   * @param {Number} offAxisAngle
   * @param {File} xFile
   * @param {File} yFile
   * @param {File} fFile
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AnalysisResult}
   */
  runFatigueFailureFile(
    method,
    snModel,
    desirableAngle,
    offAxisAngle,
    xFile,
    yFile,
    fFile
  ) {
    return this.runFatigueFailureFileWithHttpInfo(
      method,
      snModel,
      desirableAngle,
      offAxisAngle,
      xFile,
      yFile,
      fFile
    ).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Run Sn Curve File
   * @param {module:model/SnCurveMethod} method
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AnalysisResult} and HTTP response
   */
  runSnCurveFileWithHttpInfo(method, file) {
    let postBody = null;
    // verify the required parameter 'method' is set
    if (method === undefined || method === null) {
      throw new Error(
        "Missing the required parameter 'method' when calling runSnCurveFile"
      );
    }
    // verify the required parameter 'file' is set
    if (file === undefined || file === null) {
      throw new Error(
        "Missing the required parameter 'file' when calling runSnCurveFile"
      );
    }

    let pathParams = {};
    let queryParams = {
      method: method,
    };
    let headerParams = {};
    let formParams = {
      file: file,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = AnalysisResult;
    return this.apiClient.callApi(
      "/analysis/snCurve/file",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Run Sn Curve File
   * @param {module:model/SnCurveMethod} method
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AnalysisResult}
   */
  runSnCurveFile(method, file) {
    return this.runSnCurveFileWithHttpInfo(method, file).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }
}
