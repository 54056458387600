/**
 * ccfatigue
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ExperimentDataPreprocessed from "../model/ExperimentDataPreprocessed";
import FatigueTest from "../model/FatigueTest";
import PageExperimentModel from "../model/PageExperimentModel";
import QuasiStaticTest from "../model/QuasiStaticTest";

/**
 * Experiments service.
 * @module api/ExperimentsApi
 * @version 0.1.0
 */
export default class ExperimentsApi {
  /**
   * Constructs a new ExperimentsApi.
   * @alias module:api/ExperimentsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Get Experiments
   * Get all experiments
   * @param {Object} opts Optional parameters
   * @param {String} opts.query  (default to '')
   * @param {String} opts.textSearch  (default to '')
   * @param {Number} opts.page  (default to 1)
   * @param {Number} opts.size  (default to 50)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PageExperimentModel} and HTTP response
   */
  getExperimentsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      query: opts["query"],
      text_search: opts["textSearch"],
      page: opts["page"],
      size: opts["size"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = PageExperimentModel;
    return this.apiClient.callApi(
      "/experiments",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Get Experiments
   * Get all experiments
   * @param {Object} opts Optional parameters
   * @param {String} opts.query  (default to '')
   * @param {String} opts.textSearch  (default to '')
   * @param {Number} opts.page  (default to 1)
   * @param {Number} opts.size  (default to 50)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PageExperimentModel}
   */
  getExperiments(opts) {
    return this.getExperimentsWithHttpInfo(opts).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Get Fatigue Test
   * Return test result data
   * @param {Number} experimentId
   * @param {Number} testId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FatigueTest} and HTTP response
   */
  getFatigueTestWithHttpInfo(experimentId, testId) {
    let postBody = null;
    // verify the required parameter 'experimentId' is set
    if (experimentId === undefined || experimentId === null) {
      throw new Error(
        "Missing the required parameter 'experimentId' when calling getFatigueTest"
      );
    }
    // verify the required parameter 'testId' is set
    if (testId === undefined || testId === null) {
      throw new Error(
        "Missing the required parameter 'testId' when calling getFatigueTest"
      );
    }

    let pathParams = {
      experiment_id: experimentId,
      test_id: testId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = FatigueTest;
    return this.apiClient.callApi(
      "/experiments/{experiment_id}/fatigue/{test_id}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Get Fatigue Test
   * Return test result data
   * @param {Number} experimentId
   * @param {Number} testId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FatigueTest}
   */
  getFatigueTest(experimentId, testId) {
    return this.getFatigueTestWithHttpInfo(experimentId, testId).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Get Field Distinct
   * Get all distinct values for field column, sorted
   * @param {module:model/ExperimentFieldNames} field
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
   */
  getFieldDistinctWithHttpInfo(field) {
    let postBody = null;
    // verify the required parameter 'field' is set
    if (field === undefined || field === null) {
      throw new Error(
        "Missing the required parameter 'field' when calling getFieldDistinct"
      );
    }

    let pathParams = {
      field: field,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = ["String"];
    return this.apiClient.callApi(
      "/experiments/{field}/distinct",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Get Field Distinct
   * Get all distinct values for field column, sorted
   * @param {module:model/ExperimentFieldNames} field
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
   */
  getFieldDistinct(field) {
    return this.getFieldDistinctWithHttpInfo(field).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }

  /**
   * Get Quasi Static Test
   * Return quasi static test
   * @param {Number} experimentId
   * @param {Number} testId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/QuasiStaticTest} and HTTP response
   */
  getQuasiStaticTestWithHttpInfo(experimentId, testId) {
    let postBody = null;
    // verify the required parameter 'experimentId' is set
    if (experimentId === undefined || experimentId === null) {
      throw new Error(
        "Missing the required parameter 'experimentId' when calling getQuasiStaticTest"
      );
    }
    // verify the required parameter 'testId' is set
    if (testId === undefined || testId === null) {
      throw new Error(
        "Missing the required parameter 'testId' when calling getQuasiStaticTest"
      );
    }

    let pathParams = {
      experiment_id: experimentId,
      test_id: testId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["application/json"];
    let returnType = QuasiStaticTest;
    return this.apiClient.callApi(
      "/experiments/{experiment_id}/quasi-static/{test_id}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Get Quasi Static Test
   * Return quasi static test
   * @param {Number} experimentId
   * @param {Number} testId
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/QuasiStaticTest}
   */
  getQuasiStaticTest(experimentId, testId) {
    return this.getQuasiStaticTestWithHttpInfo(experimentId, testId).then(
      function (response_and_data) {
        return response_and_data.data;
      }
    );
  }

  /**
   * Post Data Preprocess Check
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExperimentDataPreprocessed} and HTTP response
   */
  postDataPreprocessCheckWithHttpInfo(file) {
    let postBody = null;
    // verify the required parameter 'file' is set
    if (file === undefined || file === null) {
      throw new Error(
        "Missing the required parameter 'file' when calling postDataPreprocessCheck"
      );
    }

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      file: file,
    };

    let authNames = [];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["application/json"];
    let returnType = ExperimentDataPreprocessed;
    return this.apiClient.callApi(
      "/experiments/data_preprocess_check",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      null
    );
  }

  /**
   * Post Data Preprocess Check
   * @param {File} file
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExperimentDataPreprocessed}
   */
  postDataPreprocessCheck(file) {
    return this.postDataPreprocessCheckWithHttpInfo(file).then(function (
      response_and_data
    ) {
      return response_and_data.data;
    });
  }
}
